<template>
    <div
        class="flex items-center justify-between border-b border-gray-200 px-6 py-4"
    >
        <h5 class="text-lg font-medium leading-9 text-gray-900">
            {{ $t('accountantInbox.detail') }}
        </h5>
    </div>
    <div
        class="flex items-center gap-1 border-b border-gray-200 p-6 text-sm font-medium"
    >
        <span class="w-20 text-gray-500">
            {{ $t('accountantInbox.assignee') }}
        </span>
        <div class="flex flex-1 items-center gap-2.5 overflow-auto">
            <base-avatar size="xs" :user="centralUser" />
            <span class="flex-1 truncate text-gray-900">
                {{ centralUser.full_name }}
            </span>
        </div>
    </div>
    <div class="flex flex-col p-6 text-sm font-medium" v-if="client">
        <base-accordion
            trigger-classes="mb-6"
            :icon-bordered="false"
            :expanded="true"
        >
            <template #title>
                <span class="text-gray-900">
                    {{ $t('accountantInbox.clientData') }}
                </span>
            </template>

            <template #icon="{ isOpen }">
                <base-icon
                    v-if="isOpen"
                    name="line-icons:arrows:chevron-up"
                    size="sm"
                    variant="gray"
                />
                <base-icon
                    v-else
                    name="line-icons:arrows:chevron-down"
                    size="sm"
                    variant="gray"
                />
            </template>

            <template #content>
                <div class="flex flex-col gap-2.5">
                    <div
                        class="flex items-center gap-1"
                        v-if="client?.tenant?.email"
                    >
                        <span class="w-20 text-gray-500">
                            {{ $t('accountantInbox.email') }}
                        </span>
                        <span class="flex-1 truncate text-gray-900">
                            {{ client?.tenant?.email }}
                        </span>
                    </div>
                    <div
                        class="flex items-center gap-1"
                        v-if="client?.tenant?.name"
                    >
                        <span class="w-20 text-gray-500">
                            {{ $t('accountantInbox.company') }}
                        </span>
                        <span class="flex-1 truncate text-gray-900">
                            {{ client?.tenant?.name }}
                        </span>
                    </div>
                    <div
                        class="flex items-center gap-1"
                        v-if="client?.tenant?.company?.phone"
                    >
                        <span class="w-20 text-gray-500">
                            {{ $t('accountantInbox.phone') }}
                        </span>
                        <span class="flex-1 truncate text-gray-900">
                            {{ client?.tenant?.company?.phone }}
                        </span>
                    </div>
                </div>
            </template>
        </base-accordion>
    </div>
</template>
<script setup>
const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
})

const { centralUser } = useAuth()
const client = computed(() => props.room?.chat_room_email)
</script>
